<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import accountUtils from "../../utils/account/accountUtils";
import { mapActions, mapGetters } from "vuex";
// import bookingUtils from "../../../utils/booking/bookingUtils";
import securityApi from "../../api/SecurityApi";
import accountApi from "../../api/accountApi";
export default {
  name: "FullLogin",
  mixins: [validationMixin],
  data: () => {
    let date = new Date();
    let year = date.getFullYear();

    return {
      email: "",
      password: "",
      //password: "12345@aA123W",
      captchaCode: "",
      captchaUrl: "",
      currentYear: year,
      checkbox: true,
      termsIndicator: false,
      isOverLayShow: false,
      submitted: false,
      alertSettings: {
        dismissCountDown: 0,
        countDownSecs: 4,
        title: "",
        alertMsg: "",
        alertVariant: "warning"
      },
      authorizationUrl:
        "/api/v1/account/authentication/keycloak?forwardToFailedUrl=" +
        window.location.href +
        "&forwardToUrl=" +
        window.location.protocol +
        "//" +
        window.location.host +
        "/sso"
    };
  },
  computed: {
    ...mapGetters("account", ["accountInfo"]),
    ...mapGetters("booking", ["userInfo"])
  },
  validations: {
    email: {
      required,
      minLength: minLength(4)
    },
    password: {
      required,
      minLength: minLength(4)
    }
    // captchaCode: {
    //     required,
    //     minLength: minLength(4),
    // },
  },
  methods: {
    ...mapActions("account", [
      "loginActions",
      "getPermissionsById"
      // "searchSelectedHotel"
    ]),
    ...mapActions("booking", ["getUserInfo"]),
    countDownChanged(dismissCountDown) {
      this.alertSettings.dismissCountDown = dismissCountDown;
    },
    login() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isOverLayShow = true;
        let userName = this.email;
        let password = this.password;
        // let loginUri = accountUtils.getAuthenticationRQ(userName, password);
        let formData = new URLSearchParams();
        formData.append("username", userName);
        formData.append("password", password);

        this.loginActions({
          formData: formData
        });
      }
    },
    loginFromSSO() {
      accountApi.loginFromSSO().then(res => {
        console.log(res.data);
      });
    },
    loginB2B() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isOverLayShow = true;
        let userName = this.email;
        let password = this.password;
        let captcha = this.captchaCode;
        let loginUri = accountUtils.getB2BAuthenticationRQ(
          userName,
          password,
          captcha
        );
        // this.$store.commit("SET_SIDEBAR_index", 0); for old template only
        this.loginActions(loginUri);
      }
    },
    getCaptchaUrl() {
      securityApi.getCaptcha().then(res => {
        let captchaEle = document.getElementById("captcha");
        var reader = new FileReader();
        reader.onload = e => {
          if (captchaEle) {
            captchaEle.src = e.target.result;
          }
        };
        reader.readAsDataURL(res.data);
      });
    }
  },
  watch: {
    accountInfo: function() {
      this.$nextTick(function() {
        this.isOverLayShow = false;
        if (this.accountInfo.success) {
          //加载用户的权限
          // let userLoginId = this.accountInfo.loginId

          sessionStorage.setItem("logedIn", true);
          sessionStorage.setItem(
            "accountInfo",
            JSON.stringify(this.accountInfo)
          );
          sessionStorage.setItem(
            "requestedCurrency",
            this.accountInfo.requestedCurrency
          );
          sessionStorage.setItem("loginTime", new Date());
          var sdate1 = new Date();
          sdate1.setMinutes(sdate1.getMinutes() + 30);
          sessionStorage.setItem("expiredTime", sdate1);
          //路由跳转
          this.$router.push(
            this.$route.query.redirectFrom || { path: "/rail" }
          );
        } else {
          this.alertSettings.title = this.accountInfo.errorTitle;
          this.alertSettings.alertMsg = this.accountInfo.errorMsg;
          this.alertSettings.alertVariant = "danger";
          this.alertSettings.dismissCountDown =
            this.alertSettings.countDownSecs + 10;
        }
      });
    },
    termsIndicator: function(newValue, oldValue) {
      console.info(newValue);
      console.info(oldValue);
    }
  }
  // created() {
  //   this.getCaptchaUrl();
  //     console.log(this.$i18n.locale)
  // }
};
</script>

<template>
  <b-overlay :show="isOverLayShow" rounded="sm">
    <div>
      <div class="home-btn d-none d-sm-block">
        <a href="/login">
          <i class="mdi mdi-home-variant h2 text-secondary"></i>
        </a>
      </div>
      <div>
        <div class="container-fluid p-0">
          <div class="row no-gutters">
            <div class="col-lg-4">
              <div
                class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
              >
                <div class="w-100">
                  <div class="row justify-content-center">
                    <div class="col-lg-9">
                      <div>
                        <div class="text-center">
                          <div>
                            <a href="/login" class="logo">
                              <img
                                src="@/assets/images/logo-dark-jebsen.png"
                                height="50"
                                alt="logo"
                              />
                            </a>
                          </div>

                          <h4 class="font-size-18 mt-4">Welcome Back !</h4>
                          <p class="text-muted">
                            Log in to continue to Jebsen Travel
                          </p>
                        </div>

                        <!-- <b-alert
                        variant="danger"
                        class="mt-3"
                        v-if="notification.message"
                        show
                        dismissible
                      >{{notification.message}}</b-alert> -->

                        <div class="p-2 mt-5">
                          <form class="form-horizontal" @submit.prevent="login">
                            <div class="form-group auth-form-group-custom mb-4">
                              <i
                                class="ri-mail-line auti-custom-input-icon"
                              ></i>
                              <label for="email">Email</label>
                              <input
                                type="text"
                                v-model="email"
                                class="form-control"
                                id="email"
                                placeholder="Enter email"
                                :class="{
                                  'is-invalid': submitted && $v.email.$error
                                }"
                              />
                              <div
                                v-if="submitted && $v.email.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.email.required"
                                  >Email is required.</span
                                >
                                <span v-if="!$v.email.email"
                                  >Please enter valid email.</span
                                >
                              </div>
                            </div>

                            <div class="form-group auth-form-group-custom mb-4">
                              <i
                                class="ri-lock-2-line auti-custom-input-icon"
                              ></i>
                              <label for="userpassword">Password</label>
                              <input
                                v-model="password"
                                type="password"
                                class="form-control"
                                id="userpassword"
                                placeholder="Enter password"
                                :class="{
                                  'is-invalid': submitted && $v.password.$error
                                }"
                              />
                              <div
                                v-if="submitted && !$v.password.required"
                                class="invalid-feedback"
                              >
                                Password is required.
                              </div>
                            </div>

                            <!-- <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-key-line auti-custom-input-icon"></i>
                            <label for="captchaCode">Captcha</label>
                            <input
                                v-model="captchaCode"
                                type="text"
                                class="form-control"
                                id="captchaCode"
                                placeholder="Enter captcha"
                                :class="{ 'is-invalid': submitted && $v.captchaCode.$error }"
                            />
                            <div
                                v-if="submitted && !$v.captchaCode.required"
                                class="invalid-feedback"
                            >Captcha is required.</div>
                          </div> -->

                            <!-- <div class="form-group auth-form-group-custom mb-4">
                            <img id="captcha" height="50" alt="logo" @click="getCaptchaUrl" />
                          </div> -->

                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="customControlInline"
                              />
                              <label
                                class="custom-control-label"
                                for="customControlInline"
                                >Remember me</label
                              >
                            </div>

                            <div class="mt-4 text-center">
                              <button
                                class="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Log In
                              </button>
                            </div>

                            <div class="mt-4 text-center">
                              <router-link
                                tag="a"
                                to="/forgot-password"
                                class="text-muted"
                              >
                                <i class="mdi mdi-lock mr-1"></i> Forgot your
                                password?
                              </router-link>
                            </div>
                          </form>
                        </div>

                        <div class="mt-2 text-center">
                          <!-- <b-button
						variant="link"
						@click="loginFromSSO"
						>Log In From SSO</b-button> -->
                          <a
                            :href="authorizationUrl"
                            class="text-decoration-none"
                            >Log In From SSO</a
                          >
                        </div>

                        <div class="mt-5 text-center">
                          <!-- <p>
                          Don't have an account ?
                          <router-link
                            tag="a"
                            to="/register"
                            class="font-weight-medium text-primary"
                          >Register</router-link>
                        </p> -->
                          <p>
                            © {{ currentYear }} Jebsen Travel
                            <!-- <i class="mdi mdi-heart text-danger"></i> -->
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="authentication-bg">
                <!-- <div class="bg-overlay"></div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-alert
        :show="alertSettings.dismissCountDown"
        class="position-fixed fixed-top m-0 rounded-0 text-center"
        style="z-index: 2000;"
        :variant="alertSettings.alertVariant"
        dismissible
        @dismiss-count-down="countDownChanged"
      >
        <!--      <div v-html="alertSettings.title"></div>-->
        <div v-html="alertSettings.alertMsg"></div>
      </b-alert>
    </div>
  </b-overlay>
</template>
